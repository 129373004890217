import React from "react"

//components
import Layout from "../components/Layout"
import Section from "../components/Section"

const error = () => {
  const langSelect = [
    { locale: "fr", path: "/404" },
    { locale: "nl", path: "/404" },
  ]
  const langCurrent = "fr"

  return (
    <Layout langSelect={langSelect} langCurrent={langCurrent}>
      <Section>404 Page</Section>
    </Layout>
  )
}

export default error
